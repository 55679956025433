<script setup lang="ts">
import { onMounted } from 'vue'

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
})

const showToggle = () => {
  document.querySelectorAll('.linked-account').forEach((el) => {
    if (el.getAttribute('data-id') === props.accountId) {
      el.classList.toggle('menu-open')
    } else {
      el.classList.remove('menu-open')
    }
  })
}

onMounted(() => {
  const handleClickOutside = (event: MouseEvent | TouchEvent) => {
    const target = event.target as HTMLElement
    if (!target.closest('.donoraccount__menu-button')) {
      document.querySelectorAll('.linked-account').forEach((el) => {
        el.classList.remove('menu-open')
      })
    }
  }

  document.addEventListener('click', handleClickOutside)
  document.addEventListener('touchend', handleClickOutside)
})
</script>

<template>
  <button
    class="donoraccount__menu-button text-link px-2 text-dark"
    data-testid="account-menu-button"
    @click="showToggle"
  >
    <div class="donoraccount__menu-bg">
      <i class="donoraccount__menu-button-icon fas fa-ellipsis-h"></i>
      <div class="donoraccount__menu" data-testid="account-menu">
        <div class="donoraccount__open-menu-items">
          <slot />
        </div>
      </div>
    </div>
  </button>
</template>

<style>
.donoraccount__menu-button {
  --account-menu-color: var(--light-grey);

  color: var(--black);
  background: var(--white);
  display: block;
  position: relative;
  padding: 0 10px;
  border-radius: 2px;
  transition: all 0.04s ease-in-out;
}

.donoraccount__menu-button:hover,
.menu-open .donoraccount__menu-button {
  background-color: var(--account-menu-color);
}

.donoraccount__menu {
  position: absolute;
  z-index: 2;
  top: 23px;
  right: 0;
  width: 140px;
  padding: 4px;
  background: var(--account-menu-color);
  border-radius: 2px;
  box-shadow: 0 6px 20px -10px rgba(0, 0, 0, 0.2);
  display: none;
}

.menu-open .donoraccount__menu-button .donoraccount__menu {
  display: block;
}

.donoraccount__open-menu-actions {
  text-align: right;
}

.donoraccount__open-menu-items {
  font-size: 14px;
  letter-spacing: 0.3px;
  font-weight: 600;
}
.donoraccount__open-menu-items button {
  color: var(--black);
  display: block;
  width: 100%;
  padding: 6px;
  border-radius: 3px;
  font-weight: 600;
  text-align: left;
}

.donoraccount__open-menu-items button:hover {
  background-color: rgba(7, 100, 212, 0.07);
  color: var(--link-color-hover);
}
</style>
