<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { TOGGLE_LOGIN_METHOD } from '@/store/mutation-types'
import closedEnvelope from '@/assets/images/closed-envelope.svg?no-inline'
import Header from '@/components/views/manage-account/Header.vue'
import EmailForm from '@/components/views/manage-account/Login/EmailForm.vue'
import { LoginMethod } from '@/types'

const store = useStore()

const header = ref('')
const para = ref('')

const loginMethod = computed(() => store.state.auth.loginMethod)

watch(loginMethod, () => {
  setTitleAndParagraph()
})

const setTitleAndParagraph = () => {
  if (loginMethod.value === LoginMethod.CODE) {
    header.value = 'ManageAccount.Login.Start.CodeHeader'
    para.value = 'ManageAccount.Login.Start.CodeParagraph'
  } else {
    header.value = 'ManageAccount.Login.Start.MagicLinkHeader'
    para.value = 'ManageAccount.Login.Start.MagicLinkParagraph'
  }
}

const toggleLoginMethod = () => {
  store.commit(TOGGLE_LOGIN_METHOD)
}

onMounted(() => {
  setTitleAndParagraph()
})
</script>

<template>
  <div>
    <Header
      :img-src="closedEnvelope"
      img-alt="Closed envelope"
      img-width="97px"
      :header="$t(header)"
      :paragraph="$t(para)"
    />

    <div class="row justify-content-center">
      <div class="col text-center">
        <EmailForm :login-method="loginMethod" />

        <div class="mt-5 text-center">
          <div v-if="loginMethod == LoginMethod.CODE">
            {{ $t('ManageAccount.Login.Start.TroubleSigningIn') }}
            <button
              class="text-link"
              data-testid="login-link-method"
              @click.prevent="toggleLoginMethod"
            >
              {{ $t('ManageAccount.Login.Start.TroubleSigningInLinkText') }}
            </button>
          </div>
          <div v-else>
            <button class="text-link" @click.prevent="toggleLoginMethod">
              {{ $t('Buttons.Cancel') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
