<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/yup'
import { emailSchema } from '@/types'
import FormLabel from '@/components/views/manage-account/FormLabel.vue'
import FieldError from '@/components/views/manage-account/FieldError.vue'

const store = useStore()

const validationSchema = toTypedSchema(emailSchema)
const { handleSubmit, errors, defineField } = useForm({
  validationSchema,
})
const [email] = defineField('email', {
  // To prevent validation on input appearance
  validateOnModelUpdate: false,
})

const emailInput = ref(null)
const addingEmailAccount = ref(false)
const btnLoading = ref(false)
const emailRequestSuccess = ref(false)
const emailRequestFailure = ref(false)

watch(email, () => {
  emailRequestFailure.value = false
})

const accountTypeConstant = computed(
  () => store.state.accounts.accountTypeConstant
)

const showAccountForm = () => {
  addingEmailAccount.value = true
  email.value = ''
  emailRequestSuccess.value = false
  emailRequestFailure.value = false

  setTimeout(() => {
    if (emailInput.value) {
      ;(emailInput.value as HTMLInputElement).focus()
    }
  }, 0)
}

const addEmailAccount = handleSubmit(async () => {
  btnLoading.value = true

  try {
    await store.dispatch('accounts/postAccount', {
      id: email.value,
      type: accountTypeConstant.value.email,
    })
    emailRequestSuccess.value = true
    addingEmailAccount.value = false
  } catch (error) {
    emailRequestFailure.value = true
  } finally {
    btnLoading.value = false
  }
})
</script>

<template>
  <div>
    <div
      v-show="emailRequestSuccess"
      class="alert alert-success alert-dismissible show"
      data-testid="email-request-success-alert"
      role="alert"
    >
      <i18n-t
        keypath="ManageAccount.UpdateAccount.AddAccount.SuccessMessagePara1"
        tag="p"
        scope="global"
      >
        <template #email>
          <strong>
            {{ email }}
          </strong>
        </template>
      </i18n-t>
      <p class="mb-0">
        {{ $t('ManageAccount.UpdateAccount.AddAccount.SuccessMessagePara2') }}
      </p>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        @click="emailRequestSuccess = false"
      ></button>
    </div>

    <div v-if="addingEmailAccount" data-testid="add-email-account-form">
      <form class="text-left" @submit.prevent="addEmailAccount">
        <FormLabel label-for="email">
          {{ $t('ManageAccount.Login.EmailForm.FieldLabel') }}
        </FormLabel>

        <div class="d-flex gap-3">
          <input
            id="email"
            ref="emailInput"
            v-model="email"
            class="form-control form-control"
            spellcheck="false"
            :class="{ 'is-invalid': errors.email?.length }"
            name="email"
            autocomplete="email"
            :placeholder="$t('ManageAccount.Login.EmailForm.FieldPlaceholder')"
          />

          <button
            type="submit"
            class="sa-btn sa-btn-sm sa-btn-primary-solid"
            :disabled="btnLoading"
          >
            <div v-if="btnLoading" class="d-flex align-items-center">
              <div class="spinner sa-btn-spinner" role="alert"></div>
            </div>
            <div
              v-else
              v-text="$t('ManageAccount.UpdateAccount.AddAccount.AddButton')"
            ></div>
          </button>
        </div>

        <FieldError :error="errors.email" />
      </form>

      <div
        v-show="emailRequestFailure"
        class="error small text-danger"
        role="alert"
      >
        {{ $t('ManageAccount.UpdateAccount.AddAccount.FailureMessage') }}
      </div>
    </div>

    <p v-else>
      <button
        class="sa-btn sa-btn-sm sa-btn-primary-solid"
        data-testid="add-email-account-button"
        @click.prevent="showAccountForm"
      >
        Add another email
      </button>
    </p>
  </div>
</template>

<style scoped>
button[type='submit'] {
  min-width: 75px;
}

.alert {
  z-index: 0;
}
</style>
